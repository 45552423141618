<template>
  <div>
    <v-row class="px-0">
      <v-col cols="11" md="7">
        <div class="text-left subtitle black--text" style="margin-top: 2%">
          {{ $t("published") }}
        </div>
      </v-col>
      <v-col cols="1" md="2" class="text-right px-0">
        <!-- ===>{{ vacancies.totalPages }} -->
        <!-- <v-btn icon color="primary" @click="infoDialog = true">
          <v-icon>info</v-icon>
        </v-btn> -->
      </v-col>
      <v-col cols="12" md="3">
        <div class="text-right">
          <v-text-field
            v-model="textSearch"
            append-icon="mdi-magnify"
            :label="$t('search')"
            single-line
            hide-details
            dense
            outlined
          ></v-text-field>
        </div>
      </v-col>
    </v-row>
    <v-divider class="px-3 my-1"></v-divider>
    <!-- <pre>
      {{ available }}
    </pre> -->
    <v-row class="my-3">
      <v-col cols="12" md="6">
        <v-radio-group
          v-model="available"
          row
          hide-details
          class="mt-0"
          @change="applyFilter()"
        >
          <v-radio :label="$t('public')" :value="false" hide-details></v-radio>

          <v-radio :label="$t('private')" :value="true" hide-details></v-radio>
        </v-radio-group>
      </v-col>
      <v-col cols="12" md="6" class="text-right">
        <v-btn
          v-if="accessLevel(currentUser, 'publish')"
          dense
          color="primary"
          router
          to="/dashboard/entity/publish"
        >
          <v-icon left>mdi-plus</v-icon>{{ $t("new_vacancy") }}
        </v-btn>
        &nbsp;&nbsp;
        <v-btn
          :disabled="available"
          dense
          color="primary"
          @click="openShareDialog(selected, currentUser.entity.id)"
        >
          <v-icon left>mdi-share-variant</v-icon>{{ $t("share_title") }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="">
      <v-col cols="12" md="12">
        <!-- <pre>{{apollo.vacancies}}</pre> -->
        <!-- <pre>{{ getVacancies }}</pre> -->
        <v-data-table
          v-model="selectedVacancies"
          :headers="headers"
          :items="getVacancies"
          :search="textSearch"
          class="elevation-1"
          dense
          :no-data-text="$t('tooltip_view_vancancy')"
          :options.sync="options"
          :server-items-length="vacancies.totalDocuments"
          :loading="loading"
          single-expand
          expand-icon="mdi-chart-pie"
          color="primary"
        >
          <template v-slot:item.title="{ item }">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <span class="text-capitalize" v-bind="attrs" v-on="on">
                  {{
                    item.title
                      ? item.title.length > 15
                        ? item.title.substring(0, 15) + "..."
                        : item.title.substring(0, 15)
                      : ""
                  }}
                </span>
              </template>
              <div v-html="item.title"></div>
            </v-tooltip>
          </template>
          <template v-slot:item.client="{ item }">
            {{ item.client ? item.client.name : "" }}
          </template>
          <template v-slot:item.tests="{ item }">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-chip
                  small
                  color="warning"
                  class="text-center "
                  v-bind="attrs"
                  v-on="on"
                  dark
                  label
                  @click="openStagePreviewTest(item.stages, item.id)"
                  :disabled="!verifyCandidacy(item)"
                >
                  <v-icon small class="mr-2">{{ iconTests(item) }}</v-icon
                  >{{ countTests(item.stages) }}
                </v-chip>
              </template>
              <div v-html="$t('tests', item.locale)"></div>
            </v-tooltip>
          </template>
          <template v-slot:item.stages="{ item }">
            <div>
              <v-chip
                :disabled="!verifyCandidacy(item)"
                small
                color="blue"
                class="text-center"
                dark
                label
                @click="openStageDialog(item.stages, item.id, item.locale)"
              >
                <v-icon small class="mr-2">mdi-account-multiple-outline</v-icon>
                {{ item.stages ? item.stages.length : 0 }}
              </v-chip>
              <!-- <pre>{{ item.stages }}</pre> -->
            </div>
          </template>
          <template v-slot:item.candidates="{ item }">
            <div class="text-center justify-center">
              <v-chip
                :disabled="!verifyCandidacy(item)"
                small
                color="blue"
                class="text-center"
                dark
                label
                @click="go(item.id)"
              >
                <v-icon small class="mr-2">mdi-account-multiple-outline</v-icon>
                {{ item.candidacies ? item.candidacies.length : 0 }}
              </v-chip>
            </div>
          </template>
          <template v-slot:item.ranking="{ item }">
            <div class="text-center justify-center">
              <v-chip
                :disabled="!verifyCandidacy(item)"
                small
                color="primary"
                class="text-center"
                dark
                label
                @click="goToRanking(item.id)"
              >
                <v-icon small class="mr-2">mdi-account-multiple</v-icon>
                {{
                  item.candidacies
                    ? totalCandidatesRanking(item.candidacies)
                    : 0
                }}
              </v-chip>
            </div>
          </template>
          <template v-slot:item.status="{ item }">
            <div class="text-center justify-center">
              <v-chip
                small
                :color="status(item).color"
                class="text-center"
                dark
                label
                >{{
                  status(item).text != "Expirado"
                    ? status(item).text === "Publicado"
                      ? $t("published_")
                      : status(item).text
                    : $t("expired")
                }}</v-chip
              >
            </div>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-btn
              v-if="accessLevel(currentUser, 'vacancies_show_vacancy')"
              :disabled="!verifyVacancyTest(item)"
              color="blue"
              x-small
              height="30px"
              :dark="verifyVacancyTest(item)"
              link
              @click="viewVacancy(item.id)"
            >
              <v-icon small>mdi-eye-outline</v-icon>
            </v-btn>

            <v-menu bottom left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" dark icon v-bind="attrs" v-on="on">
                  <v-icon color="primary">mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list dense>
                <v-list-item
                  @click="edit(item.id)"
                  v-if="accessLevel(currentUser, 'vacancies_edit_vacancy')"
                  :disabled="!verifyVacancyTest(item)"
                >
                  <v-list-icon
                    ><v-icon small class="mr-1" color="primary"
                      >mdi-pencil</v-icon
                    ></v-list-icon
                  >
                  <v-list-item-title>{{ $t("editTest") }}</v-list-item-title>
                </v-list-item>
                <v-list-item
                  @click="openShareDialog(item)"
                  :disabled="!verifyVacancyTest(item)"
                >
                  <v-list-icon
                    ><v-icon small class="mr-1"
                      >mdi-share-variant</v-icon
                    ></v-list-icon
                  >
                  <v-list-item-title>{{ $t("share") }}</v-list-item-title>
                </v-list-item>
                <v-list-item
                  @click="openConfirmeDialog(item.id)"
                  v-if="accessLevel(currentUser, 'vacancies_remove')"
                >
                  <v-list-icon
                    ><v-icon small class="mr-1" color="error"
                      >mdi-delete</v-icon
                    ></v-list-icon
                  >
                  <v-list-item-title>{{ $t("remove") }}</v-list-item-title>
                </v-list-item>

                <!-- <v-list-item
                  @click="expandeTable"
                  v-if="accessLevel(currentUser, 'vacancies_remove')"
                >
                  <v-list-icon
                    ><v-icon small class="mr-1" color="info"
                      >mdi-chart-bar</v-icon
                    ></v-list-icon
                  >
                  <v-list-item-title>{{ $t("report") }}</v-list-item-title>
                </v-list-item> -->
              </v-list>
            </v-menu>
          </template>

          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <v-row>
                <v-col>
                  <ReportVacancy :vacancy="item" />
                </v-col>
              </v-row>
            </td>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <confirm-dialog
      :dialog="confirm"
      :id="removeId"
      :question="$t('info_job_dialog')"
      @yes="deleteVacancy"
      @no="confirm = false"
    />
    <info-view-dialog
      :dialog="infoDialog"
      :data="infoView"
      @close="infoDialog = false"
    />
    <progress-dialog :processing="isLoading" />
    <success-dialog
      :dialog="showSuccess"
      :message="success"
      @close="showSuccess = false"
    />
    <share-dialog />

    <share-dialog-vacancy />
    <preview-stage-test-list
      :dialog="previewTestDialog"
      :tests="tests"
      :stages="stages"
      :vacancyId="vacancyId"
      @close="previewTestDialog = false"
      @done="stageUpdated"
    />
    <stage-edit-dialog
      :dialog="dialogStage"
      :vacancyStages="stages"
      :vacancyId="vacancyId"
      :locale="locale"
      @close="dialogStage = false"
      @done="stageUpdated"
    ></stage-edit-dialog>
  </div>
</template>

<script>
import { DELETE_VACANCY_MUTATION } from "../graphql/Mutation.resolver";
import { GET_VACANCIES_ENTITY_PAGINATE_QUERY } from "../graphql/Query.resolver";
import { CANDIDATE_CANDIDACY_SUBSCRIPTION } from "./../graphql/Subscraption.resolver";
import { mapActions, mapGetters } from "vuex";
import { formatError } from "@/utils";
import ConfirmDialog from "./../../../components/ConfirmDialog.vue";
import InfoViewDialog from "../../../components/InfoViewDialog.vue";
import ProgressDialog from "./../../../components/ProgressDialog.vue";
import SuccessDialog from "./../../../components/SuccessDialog.vue";
import ShareDialog from "./../components/ShareDialog.vue";
import ShareDialogVacancy from "./../components/ShareDialogVacancy.vue";
import StageEditDialog from "../components/StageEditDialog.vue";
import PreviewStageTestList from "../components/PreviewStageTestList.vue";
import ReportVacancy from "../components/ReportVacancy.vue";
import dialogMixins from "@/mixins/dialog";
import Vacancy from "@/models/Vacancy";
import { statusVacancyMixins } from "@/mixins/status";
import accessLevelMixins from "@/mixins/access-level";
export default {
  name: "Vacancy",
  mixins: [dialogMixins, statusVacancyMixins, accessLevelMixins],
  components: {
    ConfirmDialog,
    InfoViewDialog,
    ProgressDialog,
    SuccessDialog,
    ShareDialog,
    ShareDialogVacancy,
    StageEditDialog,
    PreviewStageTestList,
    ReportVacancy,
  },
  data: () => ({
    available: false,
    infoDialog: false,
    dialogStage: false,
    selectedVacancies: [],
    shareDialog: false,
    textSearch: "",
    vacancy: new Vacancy(),
    vacancies: [],
    stages: [],
    vacancyId: null,
    locale: null,
    tests: [],
    previewTestDialog: false,
    lineLoading: false,
    page: 1,
    pageSize: 10,
    options: {},
    expanded: [],
    singleExpand: false,
    loading: false,
  }),
  apollo: {
    vacancies: {
      query: GET_VACANCIES_ENTITY_PAGINATE_QUERY,
      variables() {
        return {
          private: this.available,
          page: this.page,
          pageSize: this.pageSize,
        };
      },
      // fetchPolicy: "no-cache",
    },
    $subscribe: {
      candidateCandidacy: {
        query: CANDIDATE_CANDIDACY_SUBSCRIPTION,
        result({ data }) {
          this.updateVacancyCandidacyState(data.candidateCandidacy);
        },
      },
    },
  },
  watch: {
    vacancies: function(val) {
      const updateQueryParam = this.$route.query.update;
      if (updateQueryParam === "true") {
        console.log("vagas actualizadas");
        this.lineLoading = true;
        this.applyFilter();
      }
      this.setVacanciesState(val.vacancies);
    },

    options: {
      handler() {
        this.getDataFromApi(undefined);
      },
      deep: true,
    },
    textSearch: function(val) {
      console.log(val);
      if (val) {
        this.getDataFromApi(val);
      } else {
        this.applyFilter();
      }
    },
  },
  created() {
    this.applyFilter();
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/getCurrentUser",
      getVacancies: "vacancy/getVacancies",
    }),

    headers() {
      return [
        {
          text: this.$t("Job_title"),
          align: "start",
          sortable: false,
          value: "title",
        },
        { text: this.$t("recruitment_stages"), value: "stages" },
        { text: this.$t("tests"), value: "tests" },
        { text: this.$t("Job_client"), value: "client" },
        {
          text: this.$t("candidate"),
          align: "center",
          value: "candidates",
          width: "12%",
        },
        { text: "Ranking", align: "center", value: "ranking", width: "10%" },
        {
          text: this.$t("status"),
          align: "right",
          value: "status",
          width: "10%",
        },
        // {
        //   text: this.$t("report"),
        //   align: "center",
        //   sortable: false,
        //   // value: "actions",
        //   value: "data-table-expand",
        // },
        {
          text: this.$t("actions"),
          align: "center",
          value: "actions",
          sortable: false,
        },
      ];
    },
    infoView() {
      return {
        icon: "mdi-check-circle-outline",
        title: this.$t("vacancy"),
        content: this.$t("info_vacancy"),
      };
    },
    selected() {
      var vacancies = [];
      this.selectedVacancies.forEach((element) => {
        vacancies.push(element.id);
      });
      return vacancies;
    },
  },
  methods: {
    ...mapActions({
      removeVacancyState: "vacancy/removeVacancy",
      setVacanciesState: "vacancy/setVacancies",
      updateVacancyCandidacyState: "vacancy/updateVacancyCandidacy",
    }),
    openStagePreviewTest(stages, vacancyId) {
      this.tests = [];
      stages.forEach((s) => {
        this.tests.push(...s.tests);
      });
      this.previewTestDialog = true;
      this.stages = stages;
      this.vacancyId = vacancyId;
    },
    iconTests(item) {
      if (this.countTests(item.stages)) {
        return "mdi-check";
      }
      return "mdi-clipboard-text-search";
    },
    countTests(stages) {
      let count = 0;
      stages.forEach((s) => {
        if (s.tests) {
          count += parseInt(s.tests.length);
        }
      });
      return count;
    },
    openStageDialog(stages, vacancyId, locale) {
      this.stages = stages;
      this.dialogStage = true;
      this.vacancyId = vacancyId;
      this.locale = locale;
    },
    stageUpdated(stages, tests, del) {
      const vacancyIndex = this.vacancies.vacancies.findIndex(
        (vacancy) => vacancy.id === this.vacancyId
      );
      this.vacancies.vacancies[vacancyIndex].stages === stages;
      // this.vacancyId = null;
      this.dialogStage = false;
      if (tests) {
        if (del) {
          this.tests = tests;
        } else {
          console.log(stages[0].tests);
          this.tests = this.tests.concat(tests);
        }
      }
      this.applyFilter();
    },
    async applyFilter() {
      // this.loading = true;
      this.pageSize =
        this.options.itemsPerPage != -1
          ? this.options.itemsPerPage
          : this.vacancy.totalDocuments;

      let f = await this.$apollo.queries.vacancies.refetch();
      if (!f.loading) {
        // this.loading = false;
      }
    },
    openConfirmeDialog(id) {
      this.removeId = id;
      this.confirm = true;
    },
    async deleteVacancy(id) {
      this.confirm = false;
      this.isLoading = true;
      try {
        await this.$apollo.mutate({
          mutation: DELETE_VACANCY_MUTATION,
          variables: {
            id,
          },
        });
        this.removeVacancyState(id);
        this.success = this.$t("vacancy_remove");
        this.showSuccess = true;
      } catch (error) {
        this.error = formatError(error.message);
        this.showError = true;
      } finally {
        this.isLoading = false;
      }
    },
    edit(id) {
      this.$router.push({
        path: "/dashboard/entity/publish",
        query: { id: id },
      });
    },
    viewVacancy(id) {
      this.$router.push({
        path: `/dashboard/entity/details/${id}`,
        // parameter: { id: id },
      });
    },
    go(id) {
      this.$router.push({
        path: "/dashboard/entity/candidacies",
        query: { vacancyId: id },
      });
    },
    expandeTable() {
      console.log(this.expanded);
      this.singleExpand = true;
    },
    async movePage(name) {
      const { page, itemsPerPage } = this.options;
      console.log({ page, itemsPerPage });
      this.page = page;
      this.pageSize =
        itemsPerPage != -1 ? itemsPerPage : this.vacancies.totalDocuments;
      try {
        return await new Promise((resolve, reject) => {
          this.$apollo
            .query({
              query: GET_VACANCIES_ENTITY_PAGINATE_QUERY,
              variables: {
                private: this.available,
                name,
                page: this.page,
                pageSize: this.pageSize,
              },
              // fetchPolicy: "no-cache",
            })
            .then((response) => resolve(response))
            .catch((error) => reject(error));
        });
      } catch (error) {
        console.error(error);
      }
    },

    getDataFromApi(name) {
      this.loading = true;
      this.movePage(name).then((response) => {
        console.log(response.data);
        this.vacancies = response.data.vacancies;
        this.loading = false;
      });
    },

    goToRanking(id) {
      this.$router.push({
        path: "/dashboard/entity/ranking",
        query: { vacancyId: id },
      });
    },
    openShareDialog(vacancy, entityId) {
      // eslint-disable-next-line no-undef
      Fire.$emit("shareVacancy", vacancy, entityId);
    },
    openShareDialogList(vacancies) {
      // eslint-disable-next-line no-undef
      Fire.$emit("shareVacancyList", vacancies);
    },
    totalCandidatesRanking(candidates) {
      if (candidates) {
        return candidates.filter((c) => c.tests && c.tests.length > 0).length;
      } else {
        return 0;
      }
    },
    verifyCandidacy(vacancy) {
      return (
        vacancy.candidacies &&
        vacancy.candidacies.filter((c) => c.appliedFor).length > 0
      );
    },
    verifyVacancyTest(vacancy) {
      return vacancy.publicationDate ? true : false;
    },
  },
};
</script>
