<template>
  <v-container fluid>
    <v-card class=" mb-3 pa-1" :color="'#f4fdff'" flat tile>
      <v-row>
        <v-col cols="12" md="3">
          <v-card class="pa-2" outlined tile :color="'#fcb75a'">
            <v-list-item three-line>
              <v-list-item-content>
                <div class="text-h6 mb-1 font-weight-bold text-center">
                  Vaga
                </div>
                <v-list-item-title class="text-body mb-1 text-center">
                  {{ vacancy.title }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-col>
        <v-col cols="12" md="3">
          <v-card outlined tile>
            <v-system-bar color="#fcb75a" class="text-center">
              <span class="black--text font-weight-bold "
                >N&deg; total de candidaturas</span
              >
            </v-system-bar>

            <v-list-item>
              <v-list-item-title>
                <div class="text-h4 ma-5  font-weight-bold text-center">
                  {{ vacancy.candidacies.length }}
                </div>
              </v-list-item-title>
            </v-list-item>
          </v-card>
        </v-col>
        <v-col cols="12" md="3">
          <v-card outlined tile>
            <v-system-bar color="#fcb75a" class="text-center">
              <span class="black--text font-weight-bold ">
                Período de candidaturas(dias)</span
              >
            </v-system-bar>

            <v-list-item>
              <v-list-item-title>
                <div class="text-h4 ma-5  font-weight-bold text-center">
                  {{ countDates(vacancy.publicationDate, vacancy.finalDate) }}
                </div>
              </v-list-item-title>
            </v-list-item>
          </v-card>
        </v-col>
        <v-col cols="12" md="3">
          <v-card outlined tile>
            <v-system-bar color="#fcb75a" class="text-center">
              <span class="black--text font-weight-bold ">
                Candidatos contratados</span
              >
            </v-system-bar>

            <v-list-item>
              <v-list-item-title>
                <div class="text-h4 ma-5  font-weight-bold text-center">
                  0
                </div>
              </v-list-item-title>
            </v-list-item>
          </v-card>
        </v-col>
      </v-row>
    </v-card>

    <v-card class=" mb-1 pa-1" :color="'#f4fdff'" flat tile>
      <v-row>
        <v-col cols="12" md="8">
          <v-card class="pa-2 mb-3" outlined tile>
            Etapas de recrutamento
          </v-card>
          <v-card class="pa-2" outlined tile>
            Tempo(Dias)
          </v-card>
        </v-col>
        <v-col cols="12" md="4">
          <v-card outlined tile class="mb-3">
            <v-system-bar color="#fcb75a" class="text-center">
              <span class="black--text font-weight-bold ">
                Total de Candidaturas em relação ao género</span
              >
            </v-system-bar>
          </v-card>
          <v-card outlined tile>
            <v-system-bar color="#fcb75a" class="text-center">
              <span class="black--text font-weight-bold ">
                Total de Candidaturas em relação às idades</span
              >
            </v-system-bar>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "ReportVacancy",
  props: {
    vacancy: Object,
  },
  data: () => ({}),
  methods: {
    countDates(dataInicial, dataFinal) {
      const inicio = new Date(dataInicial);
      const fim = new Date(dataFinal);
      const umDiaEmMilissegundos = 24 * 60 * 60 * 1000; // Um dia em milissegundos
      const diferencaEmMilissegundos = fim - inicio;
      return Math.floor(diferencaEmMilissegundos / umDiaEmMilissegundos);
    },
  },
};
</script>
